<template>
  <el-drawer
    :title="currentId ? '编辑' : '新增'"
    :visible.sync="showDrawer"
    append-to-body
    direction="rtl"
    size="800px"
    @close="onDrawerClose"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="100px"
      class="edit-form"
      label-position="top"
      v-loading="loading"
      :disabled="submitting"
    >
      <el-row :gutter="10">
        <el-col :sm="24" :md="8">
          <el-form-item label="中文名称" prop="nameCn">
            <el-input
              v-model="form.nameCn"
              clearable
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item label="英文名称" prop="nameEn">
            <el-input
              v-model="form.nameEn"
              clearable
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <!-- @keyup.enter.native="submitForm('form')" -->
          <el-form-item label="次序(数值越大越靠前)" prop="seq">
            <el-input-number
              v-model="form.seq"
              label="请输入"
              :min="1"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="菜单图标" prop="menuImageUrl">
        <p class="form-tips">
          提示：用作筛选时显示的图片，建议[24*24]、[48*48]等同类型尺寸的图片,建议大小保持在500KB以内
        </p>
        <SingleImgUpload
          :size="1"
          class="icon-img"
          v-model="form.menuImageUrl"
        />
      </el-form-item>
      <el-form-item label="封面图片" prop="coverImageUrl">
        <p class="form-tips">
          提示：该图会用在列表和详情介绍的封面图，图片宽除于高的比值保持在1.5，建议1280*853，大小保持在1MB左右，越高清越好
        </p>
        <SingleImgUpload
          class="cover-img"
          :size="2"
          v-model="form.coverImageUrl"
        />
      </el-form-item>
      <el-form-item label="图册" prop="albums">
        <el-upload
          :disabled="isAlbumsLoading"
          class="upload-albums"
          ref="albumsUpload"
          :action="onUploadUrl"
          :headers="headers"
          :before-upload="handleAlbumsBeforeUpload"
          :on-success="handleAlbumsSuccess"
          :on-error="handleAlbumsError"
          :auto-upload="true"
          :show-file-list="false"
          :multiple="true"
        >
          <el-button
            slot="trigger"
            size="small"
            type="primary"
            :icon="isAlbumsLoading ? 'el-icon-loading' : 'el-icon-upload2'"
            :disabled="isAlbumsLoading"
            >{{ isAlbumsLoading ? "上传中..." : "选取文件" }}</el-button
          >
          <div slot="tip" class="el-upload__tip">
            <p>限制文件类型为jpeg、jpg、png</p>
            <p>
              图片尺寸比例建议0.8，计算方式为宽度除于高度，例如329/401，图片大小尽量保持在1MB以内
            </p>
          </div>
        </el-upload>
        <DraggbleSort syncSortKey v-model="form.albums" class="file-list-wrap">
          <template v-slot:default="slotProps">
            <div class="file-item">
              <div class="image-wrapper">
                <img :src="slotProps.item.url" />
              </div>
              <div class="file-content">
                <el-input
                  size="small"
                  v-model="slotProps.item.nameCn"
                  placeholder="请输入中文名称"
                  ><template slot="prepend">中文</template></el-input
                >
                <el-input
                  size="small"
                  v-model="slotProps.item.nameEn"
                  placeholder="请输入英文名称"
                  ><template slot="prepend">英文</template></el-input
                >
                <!-- <p>{{ slotProps.item.nameCn }}</p>
                    <p>{{ slotProps.item.nameEn }}</p> -->
              </div>
              <div
                class="delete-btn"
                @click="handleRemoveAlbums(slotProps.index)"
              >
                <i class="el-icon-delete"></i>
              </div>
              <div class="darg-btn">
                <i class="el-icon-rank"></i>
              </div>
            </div>
          </template>
        </DraggbleSort>
      </el-form-item>
      <el-form-item label="附件" prop="files">
        <el-upload
          :disabled="isFileLoading"
          class="upload-albums"
          ref="albumsUpload"
          :action="onUploadUrl"
          :headers="headers"
          :before-upload="handleFilesBeforeUpload"
          :on-success="handleFilesSuccess"
          :on-error="handleFilesError"
          :auto-upload="true"
          :show-file-list="false"
          :multiple="true"
        >
          <el-button
            slot="trigger"
            size="small"
            type="primary"
            :icon="isFileLoading ? 'el-icon-loading' : 'el-icon-upload2'"
            :disabled="isFileLoading"
            >{{ isFileLoading ? "上传中..." : "选取文件" }}</el-button
          >
          <div slot="tip" class="el-upload__tip">
            文件类型不限制，文件最大建议保持在15MB以内
          </div>
        </el-upload>
        <DraggbleSort syncSortKey v-model="form.files" class="file-list-wrap">
          <template v-slot:default="slotProps">
            <div class="file-item">
              <div class="image-wrapper">
                <a
                  :href="slotProps.item.url"
                  target="_blank"
                  download="true"
                  rel="noopener noreferrer"
                  title="点击预览"
                >
                  <i class="el-icon-document"></i>
                </a>
              </div>
              <div class="file-content">
                <el-input
                  size="small"
                  v-model="slotProps.item.nameCn"
                  placeholder="请输入中文名称"
                  ><template slot="prepend">中文</template></el-input
                >
                <el-input
                  size="small"
                  v-model="slotProps.item.nameEn"
                  placeholder="请输入英文名称"
                  ><template slot="prepend">英文</template></el-input
                >
              </div>
              <div
                class="delete-btn"
                @click="handleRemoveFiles(slotProps.index)"
              >
                <i class="el-icon-delete"></i>
              </div>
              <div class="darg-btn">
                <i class="el-icon-rank"></i>
              </div>
            </div>
          </template>
        </DraggbleSort>
      </el-form-item>

      <el-form-item label="资料图" prop="images">
        <el-upload
          :disabled="isImagesLoading"
          class="upload-albums"
          ref="albumsUpload"
          :action="onUploadUrl"
          :headers="headers"
          :before-upload="handleImagesBeforeUpload"
          :on-success="handleImagesSuccess"
          :on-error="handleImagesError"
          :auto-upload="true"
          :show-file-list="false"
          :multiple="true"
        >
          <el-button
            slot="trigger"
            size="small"
            type="primary"
            :icon="isImagesLoading ? 'el-icon-loading' : 'el-icon-upload2'"
            :disabled="isImagesLoading"
            >{{ isImagesLoading ? "上传中..." : "选取文件" }}</el-button
          >
          <div slot="tip" class="el-upload__tip">
            <p>限制文件类型为jpeg、jpg、png</p>
            <p>
              图片尺寸比例建议1.2，计算方式为宽度除于高度，例如262/215，图片大小尽量保持在1MB以内
            </p>
          </div>
        </el-upload>
        <DraggbleSort syncSortKey v-model="form.images" class="file-list-wrap">
          <template v-slot:default="slotProps">
            <div class="file-item">
              <div class="image-wrapper">
                <img :src="slotProps.item.url" />
              </div>
              <div class="file-content">
                <el-input
                  size="small"
                  v-model="slotProps.item.nameCn"
                  placeholder="请输入中文名称"
                  ><template slot="prepend">中文</template></el-input
                >
                <el-input
                  size="small"
                  v-model="slotProps.item.nameEn"
                  placeholder="请输入英文名称"
                  ><template slot="prepend">英文</template></el-input
                >
                <!-- <p>{{ slotProps.item.nameCn }}</p>
                    <p>{{ slotProps.item.nameEn }}</p> -->
              </div>
              <div
                class="delete-btn"
                @click="handleRemoveImages(slotProps.index)"
              >
                <i class="el-icon-delete"></i>
              </div>
              <div class="darg-btn">
                <i class="el-icon-rank"></i>
              </div>
            </div>
          </template>
        </DraggbleSort>
      </el-form-item>

      <el-form-item style="text-align: right">
        <!-- <el-button @click="onCancel('form')">关 闭</el-button> -->
        <el-button @click="onResetForm('form')">重 置</el-button>
        <el-button
          v-if="currentId"
          type="warning"
          @click="submitEidtForm('form')"
          :loading="submitting"
          >{{ submitting ? "保存中..." : "保存" }}</el-button
        >
        <el-button
          v-else
          type="primary"
          @click="submitForm('form')"
          :loading="submitting"
          >{{ submitting ? "保存中..." : "保存" }}</el-button
        >
      </el-form-item>
    </el-form>
  </el-drawer>
</template>

<script>
import {
  PutMosaicSeriesById,
  PostMosaicSeries,
  GetMosaicSeriesById,
} from "../api";
import { ShowApiError } from "@/request/error";
import SingleImgUpload from "@/components/SingleImgUpload";
import API_CONFIG from "@/request/api.config";
import { getToken } from "@/utils/auth";
import DraggbleSort from "@/components/DraggbleSort";
export default {
  props: {
    sid: {
      type: [String, Number],
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: { SingleImgUpload, DraggbleSort },
  data() {
    return {
      currentId: null,
      loading: false,
      submitting: false,
      showDrawer: false,
      isAlbumsLoading: false,
      isFileLoading: false,
      isImagesLoading: false,
      form: {},
      rules: {
        nameCn: [{ required: true, message: "请输入", trigger: "blur" }],
        nameEn: [{ required: true, message: "请输入", trigger: "blur" }],
        seq: [
          {
            required: true,
            message: "请输入次序",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    onUploadUrl() {
      return API_CONFIG.BASE_V1_API + "/oss/upload/cdn";
    },
    headers() {
      return {
        Authorization: getToken(),
      };
    },
  },
  watch: {
    sid: {
      handler(value) {
        if (value !== null) {
          this.currentId = value;
        } else {
          this.currentId = null;
          this.initForm();
        }
      },
      immediate: true,
    },
    show: {
      handler(value) {
        this.showDrawer = value;
        if (this.currentId) {
          this.getFormData();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.initForm();
  },
  methods: {
    handleAlbumsBeforeUpload(file) {
      let allowType = ["image/jpeg", "image/jpg", "image/png"];
      if (!allowType.includes(file.type)) {
        this.$message.error(`上传的图片格式不合法,格式需要为jpeg/jpg/png`);
        return false;
      }
      this.isAlbumsLoading = true;
      return true;
    },
    handleAlbumsSuccess(response, file) {
      this.form.albums.push({
        nameCn: file.name,
        nameEn: file.name,
        url: file.response ? file.response : "",
        seq: this.form.albums.length + 1,
      });
      this.isAlbumsLoading = false;
      this.$message.success(`文件${file.name}上传成功`);
      // {
      //   name: "极氪登录.png";
      //   percentage: 100;
      //   raw: File;
      //   response: "https://cdn.viewgres.com/b5116680e1161848344d1839d8193b82";
      //   size: 670945;
      //   status: "success";
      //   uid: 1703561183286;
      // }
    },
    handleAlbumsError(err, file) {
      console.error(err);
      this.$message.error(`文件${file.name}上传失败`);
      this.isAlbumsLoading = false;
    },
    handleRemoveAlbums(index) {
      this.form.albums.splice(index, 1);
    },

    handleFilesBeforeUpload() {
      this.isFileLoading = true;
      return true;
    },
    handleFilesSuccess(response, file) {
      this.form.files.push({
        nameCn: file.name,
        nameEn: file.name,
        url: file.response ? file.response : "",
        seq: this.form.files.length + 1,
      });
      this.isFileLoading = false;
      this.$message.success(`文件${file.name}上传成功`);
      // {
      //   name: "极氪登录.png";
      //   percentage: 100;
      //   raw: File;
      //   response: "https://cdn.viewgres.com/b5116680e1161848344d1839d8193b82";
      //   size: 670945;
      //   status: "success";
      //   uid: 1703561183286;
      // }
    },
    handleFilesError(err, file) {
      console.error(err);
      this.$message.error(`文件${file.name}上传失败`);
      this.isFileLoading = false;
    },
    handleRemoveFiles(index) {
      this.form.files.splice(index, 1);
    },

    handleImagesBeforeUpload(file) {
      let allowType = ["image/jpeg", "image/jpg", "image/png"];
      if (!allowType.includes(file.type)) {
        this.$message.error(`上传的图片格式不合法,格式需要为jpeg/jpg/png`);
        return false;
      }
      this.isImagesLoading = true;
      return true;
    },
    handleImagesSuccess(response, file) {
      this.form.images.push({
        nameCn: file.name,
        nameEn: file.name,
        url: file.response ? file.response : "",
        seq: this.form.images.length + 1,
      });
      this.isImagesLoading = false;
      this.$message.success(`文件${file.name}上传成功`);
      // {
      //   name: "极氪登录.png";
      //   percentage: 100;
      //   raw: File;
      //   response: "https://cdn.viewgres.com/b5116680e1161848344d1839d8193b82";
      //   size: 670945;
      //   status: "success";
      //   uid: 1703561183286;
      // }
    },
    handleImagesError(err, file) {
      console.error(err);
      this.$message.error(`文件${file.name}上传失败`);
      this.isImagesLoading = false;
    },
    handleRemoveImages(index) {
      this.form.images.splice(index, 1);
    },

    buildFormParams() {
      return {
        ...this.form,
        albums: this.form.albums,
        files: this.form.files,
      };
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PostMosaicSeries([this.buildFormParams()])
            .then(() => {
              this.$message.success("保存成功!");
              this.initForm();
              this.submitting = false;
              this.emitSucccess();
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("保存错误", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    submitEidtForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutMosaicSeriesById(this.currentId, this.buildFormParams())
            .then(() => {
              this.$message.success("保存成功!");
              this.initForm();
              this.submitting = false;
              this.getFormData();
              this.emitSucccess();
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("保存错误", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getFormData() {
      this.loading = true;
      GetMosaicSeriesById(this.currentId)
        .then((res) => {
          let {
            nameCn,
            nameEn,
            menuImageUrl,
            coverImageUrl,
            seq,
            albums,
            files,
            images
          } = res.data;
          this.form = {
            nameCn,
            nameEn,
            menuImageUrl,
            coverImageUrl,
            albums,
            files,
            images,
            seq: seq,
          };
          this.loading = false;
        })

        .catch((err) => {
          this.loading = false;
          ShowApiError(err, "获取表单数据失败");
        });
    },
    initForm() {
      this.form = {
        nameCn: "",
        nameEn: "",
        menuImageUrl: "",
        coverImageUrl: "",
        images:[],
        albums: [
          // {
          //   nameCn: "",
          //   nameEn: "",
          //   url: "",
          //   seq: 0,
          // },
        ],
        files: [
          // {
          //   nameCn: "",
          //   nameEn: "",
          //   url: "",
          //   seq: 0,
          // },
        ],
        seq: 1,
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
    onCancel() {
      this.initForm();
      this.emitFail();
    },
    onResetForm() {
      this.$confirm("确定要重置表单吗?", "操作确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.currentId) {
            this.getFormData();
          } else {
            this.initForm();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    onDrawerClose() {
      this.showDrawer = false;
      this.$emit("close", this.showDrawer);
    },
    emitSucccess() {
      this.$emit("success");
    },
    emitFail() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-img {
  width: 100px;
  height: 100px;
}
.edit-form {
  padding: 0 20px;
}
.form-tips {
  font-size: 12px;
  margin: 0;
  color: #989898;
}
.cover-img {
  width: 300px;
  height: 200px;
}
.file-list-wrap {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  max-width: 100%;
  min-height: 300px;
  border: 1px dashed #f1f1f1;
  background-color: #fffdfc;
  ::v-deep{
    .transition-el{
      box-sizing: border-box;
      width: 100%;
      padding-right: 10px;
    }
    .draggable-item{
      width: 100%;
    }
  }
  .file-item {
    box-sizing: border-box;
    width: 100%;
    height: 120px;
    padding: 10px 50px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    gap: 10px;
    border: 1px solid #f1f1f1;
    color: #989898;
    overflow: hidden;

    .darg-btn {
      width: 40px;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%);
      font-size: 20px;
      color: inherit;
      cursor: move;
    }
    .delete-btn {
      width: 40px;
      position: absolute;
      top: 50%;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%);
      font-size: 20px;
      color: inherit;
      cursor: pointer;
      &:hover {
        color: #ff3d3d;
      }
    }
    &:hover {
      // color: #fff;
      // background-color: hsl(15, 88%, 55%);
      border: 1px solid hsl(15, 88%, 55%);
      .darg-btn {
        color: #fff;
        background-color: hsl(15, 88%, 55%);
      }
    }
    .image-wrapper {
      flex: 80px 0 0;
      height: 100px;
      border: 1px solid #f1f1f1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 56px;
      overflow: hidden;
      color: #ff3d3d;
      align-self: start;
      a {
        color: inherit;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .file-content {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      gap: 4px;
    }
  }
}
</style>
